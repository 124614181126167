// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-callback-js": () => import("./../../../src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-legacy-js": () => import("./../../../src/pages/products/legacy.js" /* webpackChunkName: "component---src-pages-products-legacy-js" */),
  "component---src-pages-products-repair-portal-js": () => import("./../../../src/pages/products/repair-portal.js" /* webpackChunkName: "component---src-pages-products-repair-portal-js" */),
  "component---src-pages-products-server-js": () => import("./../../../src/pages/products/server.js" /* webpackChunkName: "component---src-pages-products-server-js" */),
  "component---src-pages-products-superbase-ng-ide-js": () => import("./../../../src/pages/products/superbase-ng-ide.js" /* webpackChunkName: "component---src-pages-products-superbase-ng-ide-js" */),
  "component---src-pages-products-superbase-ng-personal-js": () => import("./../../../src/pages/products/superbase-ng-personal.js" /* webpackChunkName: "component---src-pages-products-superbase-ng-personal-js" */),
  "component---src-pages-products-web-shop-js": () => import("./../../../src/pages/products/web-shop.js" /* webpackChunkName: "component---src-pages-products-web-shop-js" */)
}

